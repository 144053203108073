import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { UserStatus } from 'api/generated/enums';
import { Logo } from 'components/Logo';
import useTeamProps from 'hooks/useTeamProps';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH, MEMBER_SURVEY_INTRO_PATH } from 'routers/routes';
import { getDisplayFirstName } from 'utilities/household';
import Skeleton from 'components/Skeleton';
import { hasValue } from 'utilities/index';

const WelcomeMemberPage = () => {
    const { hasMemberVerifiedInfo, user } = useUserProps();
    const isInIchraPathway = user?.activeWageUps?.some(x=>x.wasSetFromIchraPathway);
    const { useIchraLaunchFlow } = useTeamProps();
    const isInIchraLaunchFlow = useIchraLaunchFlow && isInIchraPathway;
    const shouldRedirectToDashboard =
        [hasMemberVerifiedInfo, user?.status === UserStatus.Waived, isInIchraLaunchFlow].contains(
            true
        ) ?? false;
    return (
        <Skeleton isEnabled={!hasValue(isInIchraPathway)}>
            <Paper sx={{ textAlign: 'center' }}>
                <Logo height="70" />
                <Typography my={3} variant="h3">
                    Welcome, {getDisplayFirstName(user)}!
                </Typography>
                <p className="text-muted">
                    We are excited to help you find a great health plan or program that fits{' '}
                    <strong>your</strong> needs.
                </p>
                {shouldRedirectToDashboard ? (
                    <Button
                        component={Link}
                        data-cy="welcome-to-dashboard-link"
                        startIcon={<HomeIcon />}
                        to={DASHBOARD_PATH}
                        variant="outlined"
                    >
                        Go to Dashboard
                    </Button>
                ) : (
                    <Button
                        component={Link}
                        data-cy="welcome-take-survey-link"
                        startIcon={<ArrowForwardIcon />}
                        to={MEMBER_SURVEY_INTRO_PATH}
                        variant="outlined"
                    >
                        Continue
                    </Button>
                )}
            </Paper>


        </Skeleton>

    );
};

export default hot(module)(WelcomeMemberPage);
